.word-counter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #2c2c54;
    color: #ffffff;
    border-radius: 10px;
    width: 80%;
    max-width: 600px;
    margin: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.word-counter-container h1 {
    color: #7a7a9c;
}

.word-counter-container textarea {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 16px;
}

.word-counter-container button {
    background-color: #7a7a9c;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.word-counter-container button:hover {
    background-color: #5a5a7a;
}

.word-counter-container p {
    margin-top: 10px;
    font-size: 18px;
}

@media (max-width: 600px) {
    .word-counter-container {
        width: 95%;
    }
}
